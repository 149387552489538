import React from "react";
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  EditButton,
  FunctionField,
} from "react-admin";
import { format } from "date-fns";

const featureFilter = [<TextInput label="Name" source="name" />];

export const FeatureList = () => (
  <List filters={featureFilter} >
    <Datagrid  bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="key" />
      <FunctionField
        label="Last Updated"
        render={(record: { lastModifiedDate: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.lastModifiedDate),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
      <EditButton />
    </Datagrid>
  </List>
);
