import { FaUserTie } from "react-icons/fa";
import { FeatureCreate } from "../components/Features/FeatureCreate";
import { FeatureEdit } from "../components/Features/FeatureEdit";
import { FeatureList } from "../components/Features/FeatureList";

const Features = {
  name: "features",
  icon: FaUserTie,
  list: FeatureList,
  edit: FeatureEdit,
  create: FeatureCreate,
  menuLabel: "Features",
};

export default Features;
