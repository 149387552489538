import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";
import { EditToolbar } from "../CustomToolbar/EditToolbar";

export const FeatureEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="key" disabled />
      <TextInput source="name" required />
      <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
    </SimpleForm>
  </Edit>
);
