import {
  OrganizationStatuses,
  seatTypes,
  stockTypes,
  ExternalTenantTypes,
  InventorySourceTypes,
  ProductionStatuses,
} from "../../utils/constants";

export const organizationStatusChoices = [
  { id: OrganizationStatuses.Active, name: "Active" },
  { id: OrganizationStatuses.Inactive, name: "Inactive" },
  { id: OrganizationStatuses.Pending, name: "Pending" },
  { id: OrganizationStatuses.Deleted, name: "Deleted" },
];

export const stockTypeChoices = [
  { id: stockTypes.MobileQr, name: stockTypes.MobileQr },
  { id: stockTypes.MobileTransfer, name: stockTypes.MobileTransfer },
  { id: stockTypes.Eticket, name: stockTypes.Eticket },
  { id: stockTypes.Physical, name: stockTypes.Physical },
];

export const seatTypeChoices = [
  { id: seatTypes.GA, name: seatTypes.GA },
  { id: seatTypes.PiggyBack, name: seatTypes.PiggyBack },
  { id: seatTypes.Consecutive, name: seatTypes.Consecutive },
  { id: seatTypes.Odd_Even, name: seatTypes.Odd_Even },
  { id: seatTypes.Parking, name: seatTypes.Parking },
  { id: seatTypes.AdaSeating, name: seatTypes.AdaSeating },
];

export const externalTenantTypeChoices = [
  { id: ExternalTenantTypes.Skybox, name: ExternalTenantTypes.Skybox },
  { id: ExternalTenantTypes.None, name: ExternalTenantTypes.None },
];

export const sourceTypeChoices = [
  { id: InventorySourceTypes.VSSkybox, name: InventorySourceTypes.VSSkybox },
  { id: InventorySourceTypes.TicketEvolution, name: InventorySourceTypes.TicketEvolution },
  { id: InventorySourceTypes.ShowsOnSale, name: InventorySourceTypes.ShowsOnSale },
  { id: InventorySourceTypes.SeatGeek, name: InventorySourceTypes.SeatGeek },
  { id: InventorySourceTypes.TicketNetworkPosNext, name: InventorySourceTypes.TicketNetworkPosNext },
  { id: InventorySourceTypes.TicketNetworkPos11Api, name: InventorySourceTypes.TicketNetworkPos11Api },
  { id: InventorySourceTypes.TradeDesk, name: InventorySourceTypes.TradeDesk },    
 
];

export enum MarketplaceSourceTypes {
  TicketEvolution = "TicketEvolution",
  VsSkybox = "VsSkybox",
}
export const MarketplaceSourceTypesChoices = [
  { id: MarketplaceSourceTypes.TicketEvolution, name: MarketplaceSourceTypes.TicketEvolution },
  { id: MarketplaceSourceTypes.VsSkybox, name: MarketplaceSourceTypes.VsSkybox },
];

export const productionStatusChoices = [
  { id: ProductionStatuses.Active, name: ProductionStatuses.Active },
  { id: ProductionStatuses.Postponed, name: ProductionStatuses.Postponed },
  { id: ProductionStatuses.Inactive, name: ProductionStatuses.Inactive },
  { id: ProductionStatuses.Cancelled, name: ProductionStatuses.Cancelled },
  
];