import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateInput,
  EditButton,
  FunctionField,
  List,
  NumberInput,
  ReferenceManyField, SearchInput, SelectInput, SingleFieldList,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import { CustomOrganizationReferenceInput } from "../Organizations/CustomOrganizationReferenceInput";
import { FC } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import OrganizationLinkField from "../Common/OrganizationLinkField";
import { CombinedDateTimeField } from "../../utils/CombinedDateTimeField";
import { productionStatusChoices } from "../Organizations/common";


const pricingGroupFilter = [
  <SearchInput

    source="searchText_fts"
    alwaysOn />,
  <TextInput source="id" />,
  <TextInput source="name" />,
  <CustomOrganizationReferenceInput
    source="organizationKey"
    label="Organization"
  />,
  <SelectInput source="productionStatus" label="Production Status" choices={productionStatusChoices} />,
  <NumberInput source="ceilingPrice_gte" label="Ceiling Price Min" />,
  <NumberInput source="ceilingPrice_lte" label="Ceiling Price Max" />,
  <NumberInput source="floorPrice_gte" label="Floor Price Min" />,
  <NumberInput source="floorPrice_lte" label="Floor Price Max" />,
  <BooleanInput source="isAutoPricingEnabled" />,
  <DateInput label="Event Date From" source="localDate_gte" />,
  <DateInput label="Event Date To" source="localDate_lte" />,
  <NumberInput source="ticketGroupTotal_gte" label="TicketGroup Total From" />,
  <NumberInput source="ticketGroupTotal_lte" label="TicketGroup Total To" /> 
];

interface VenueFieldProps {
  record?: Record<string, any>;
  source?: string;
}
const VenueField: FC<VenueFieldProps> = ({ source }) => {
  const record = useRecordContext();
  return record && source && record[source] ? (
    <Link to={`/venues/?filter=${JSON.stringify({
      id: [record[source].venueId],
    })}`}>{record[source].venueName}</Link>
  ) : null;
};

interface ProductionFieldProps {
  record?: Record<string, any>;
  source?: string;
}
const ProductionField: FC<ProductionFieldProps> = ({ source }) => {
  const record = useRecordContext();
  return record && source && record[source] ? (
    <Link to={`/productions/?filter=${JSON.stringify({
      id: [record[source].productionId],
    })}`}>{record[source].productionName}</Link>
  ) : null;
};

export const PricingGroupList = () => {

  return (
    <List className='pg-list' filters={pricingGroupFilter}
      sx={{
        [`> div :has(.RaBulkActionsToolbar-toolbar)`]:
          { position: 'sticky', zIndex: 99999, top: '100px' },
        ['& .RaBulkActionsToolbar-toolbar']:
          { width:'320px', left:'unset !important' },
        ['& .MuiTablePagination-root']:
          { top: '50px', position: 'relative'},
          
      }}>
      <Datagrid >
        <TextField source="id" />
        <ReferenceManyField
          source="organizationKey"
          target="key"
          reference="organization-details"
          perPage={99999}
          label="Organization"
        >
          <SingleFieldList>
            <OrganizationLinkField
              source="name"
              label="Organization"
              sx={{ margin: "5px 0px 15px", display: "inline-flex" }}
            />
          </SingleFieldList>
        </ReferenceManyField>
        <TextField source="name" />
        <TextField source="ceilingPrice" />
        <TextField source="floorPrice" />

        <JsonField
          source="ruleSet"
          jsonString={false}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <JsonField
          source="marketGroupCriteria"
          jsonString={false}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <BooleanField source="isAutoPricingEnabled" />
        <ProductionField source="production" />
        <CombinedDateTimeField source={'production.productionDate'} 
        sourceDate="production.productionDate" 
        sourceTime="production.productionLocalTime" 
        isComplexObject label={"Event Date"} />
        <VenueField source="venue" />
        <TextField source="ticketGroupTotal" label='TicketGroups' />
        <TextField source="production.productionStatus" label='Status' />
        <FunctionField
          source="lastModifiedDate"
          label="Last Updated"
          render={(record: { lastModifiedDate: any; lastModifiedBy: any }) =>
            `${format(
              new Date(record.lastModifiedDate),
              "MMMM do yyyy, h:mm:ss a"
            )} by ${record.lastModifiedBy}`
          }
        />
        <FunctionField
          source="lastCalculationDate"
          label="Last Calculation"
          render={(record: { lastCalculationDate: any; }) =>
            `${record.lastCalculationDate?format(
              new Date(record.lastCalculationDate),
              "MMMM do yyyy, h:mm:ss a"
            ):''}`
          }
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
