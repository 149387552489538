import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {CircularProgress, Typography} from "@mui/material";
import {
    Button,
    useNotify,
    useDataProvider, HttpError
} from 'react-admin';

import {SourceVenueMasterMapped} from "../../utils/constants";
import {FaTicketAlt} from "react-icons/fa";

import {Tooltip} from "@mui/material";
import Card from "@mui/material/Card";
import RefreshIcon from '@mui/icons-material/Refresh';
import VenueMappingRemoveButton from "./VenueMappingRemoveButton";


const ActionButton = (props: ActionButtonProps) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [mappingSuccessful, setMappingSuccessful] = useState(false);
    const {id, venueMappingDetailsFormProps} = props;
    const [mappedMasterVenueId, setMappedMasterVenueId] = useState(venueMappingDetailsFormProps.mappedMasterVenueId);
    const handleMapClick = async () => {
        try {

            const data: SourceVenueMasterMapped = {
                sourceVenueKey: venueMappingDetailsFormProps.sourceVenueKey,
                masterVenueId: id,
                sourceType: venueMappingDetailsFormProps.sourceType
            };
            await dataProvider.create('source-venue-mappings', {data: data});
            notify('Mapping created successfully', {type: 'info'});
            setMappingSuccessful(true);
            setMappedMasterVenueId(id);
        } catch (error) {
            const err = error as HttpError;
            notify(`Error creating mapping: ${err.body[0]?.errorMessage}`, {type: 'error'});
        }
    };

    const disableMapButton = mappingSuccessful || mappedMasterVenueId === id;

    return (
        <div style={{display: 'inline-flex', alignItems: 'left'}}>

            <Button
                disabled={disableMapButton}
                onClick={() => handleMapClick()} style={{minWidth: 'auto', padding: '6px', margin: '0'}}>
                <Tooltip title="Apply Mapping">
                    <span style={{display: 'inline-block', transform: 'rotate(90deg)'}}>
                    <FaTicketAlt/>
                  </span>
                </Tooltip>
            </Button>

            {
                disableMapButton &&
                <VenueMappingRemoveButton 
                    setMappingSuccessful={setMappingSuccessful}
                    setMappedMasterVenueId={setMappedMasterVenueId}
                />
            }
            

        </div>
    );
};

interface VenueMappingDetailsFormProps {
    sourceVenueKey: string;
    sourceType: string;
    name: string;
    city: string;
    isMapped: boolean;
    mappedMasterVenueId?: number;
    refresh():  void;
}

interface ActionButtonProps {
    id: number;
    venueMappingDetailsFormProps: VenueMappingDetailsFormProps;
}

interface MasterVenueFilter {
  name: string;
  city: string;
  state: string;
  postalCode: string;
}

export const MasterVenueChild = (venueMappingDetailsFormProps: VenueMappingDetailsFormProps) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const [filter, setFilter] = useState<MasterVenueFilter>({
        name: venueMappingDetailsFormProps.name,
        city: venueMappingDetailsFormProps.city,
        state: "",
        postalCode: ""
    });
    const fetchData = async (filter: any) => {
        setLoading(true);
        
        const sort = {
            field: 'name',
            order: 'ASC'
        };
        const pagination = {
            page: 1,
            perPage: 100
        };
        
        const respData: any = await dataProvider.getList('master-venue', {filter, sort, pagination});
        setData(respData.data);
        setLoading(false);
    }
    
    useEffect(() => {
        fetchData(filter);
    }, [filter]);
    
    const handleFilterChange = (filterName: string, value: any) => {
        const newFilter = {...filter};
        switch (filterName) {
            case 'name':
                newFilter['name'] = value;
                break;
            case 'city':
                newFilter['city'] = value;
                break;
            case 'state':
                newFilter['state'] = value;
                break;
                
        }
        setFilter(newFilter);
    }

    const CustomRefreshButton = () => {
        const handleRefreshClick = () => {
            fetchData(filter);
            venueMappingDetailsFormProps.refresh();
        };

        return (
            <Button label="Refresh" onClick={handleRefreshClick}>
                <RefreshIcon />
            </Button>
        );
    };

    const VenueExternalLink = (props: any) => {
        var record = props.record;
        const url = record.sourceVenueUrl;
        console.log("VenueExternalLink");
        console.log(url);
        return (
            <TableCell align="left">
                {
                url ?
                <a href={url} style={{textDecoration: "none"}} target="_blank" rel="noreferrer">{record.sourceVenueKey}</a>
                : ""
                }
            </TableCell>
        );
    };
    
    const renderFilters = () => {
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TextField
                    sx={{marginRight: "1rem"}}
                    label="Name"
                    value={filter.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleFilterChange('name', event.target.value);
                    }}
                />
                <TextField
                    sx={{marginRight: "1rem"}}
                    label="City"
                    value={filter.city}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleFilterChange('city', event.target.value);
                    }}
                />
                <TextField
                    sx={{marginRight: "1rem"}}
                    label="State"
                    value={filter.state}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleFilterChange('state', event.target.value);
                    }}  
                />
            </LocalizationProvider>
        </div>
    );
    }
    

    return (
        <Card sx={{margin: "1rem", padding: "1rem"}}>
            <CustomRefreshButton/>
            {renderFilters()}
            {loading ? <CircularProgress />
                :
                data.length === 0 ? <Typography variant={"h3"} sx={{marginTop: "1rem"}}>No results found</Typography>
                    :
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Address</TableCell>
                            <TableCell align="left">City</TableCell>
                            <TableCell align="left">State</TableCell>
                            <TableCell align="left">Postal Code</TableCell>
                            <TableCell align="left">Master Venue Id</TableCell>
                            <TableCell align="left">VS Venue ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row: any) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <ActionButton id={row.id} venueMappingDetailsFormProps={venueMappingDetailsFormProps} />
                                </TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.address}</TableCell>
                                <TableCell align="left">{row.city}</TableCell>
                                <TableCell align="left">{row.state}</TableCell>
                                <TableCell align="left">{row.postalCode}</TableCell>
                                <TableCell align="left">{row.id}</TableCell>
                                <VenueExternalLink record={row} />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </Card>
    );
};

